import slider from "../../resources/js/modules/sliders";

const floorPlansSliders = document.querySelectorAll(".hop-floor-plans__gallery .swiper");

if (floorPlansSliders) {
    slider(floorPlansSliders, {
        slidesPerView: 1,
        spaceBetween: 30,

        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
            },

            1025: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
        },

        pagination: {
            el: ".hop-floor-plans-gallery-slider-pagination .swiper-pagination",
        },

        navigation: {
            nextEl: '.hop-floor-plans-gallery-slider-nav-btn--next',
            prevEl: '.hop-floor-plans-gallery-slider-nav-btn--prev',
        },
    });
}
